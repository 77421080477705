import { Link, useLocation, useNavigate } from "react-router-dom";
import {
	SideNavbar,
	SideNavbarItems,
	ISCLogo,
	LogoImage,
} from "./styled.components";
import logo from "../../Assets/images/logo.png";

const SideMenu = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("userName");
		localStorage.removeItem("role");
		navigate("/");
	};
	const getInitials = () => {
		if (
			localStorage.getItem("userName") &&
			localStorage.getItem("userName") !== null
		) {
			const fullName = localStorage.getItem("userName").split(" ");
			let initials = `${fullName.shift()?.charAt(0) || "A"}${
				fullName.pop()?.charAt(0) || ""
			}`;
			return initials?.toUpperCase();
		} else {
			return "A";
		}
	};
	const getName = () => {
		return localStorage.getItem("userName");
	};
	return (
		<SideNavbar selectedKeys={location.pathname}>
			<SideNavbarItems key="5">
				<ISCLogo>{getInitials()}</ISCLogo>
				<p
					style={{
						display: "inline-flex",
						lineHeight: "25px",
						marginBottom: "0px",
					}}
				>
					{getName()}
				</p>
			</SideNavbarItems>
			{location.pathname === "/super-admin" ? (
				<SideNavbarItems key="/super-admin" style={{ marginLeft: "auto" }}>
					<Link to="/super-admin">Users</Link>
				</SideNavbarItems>
			) : location.pathname === "/admin-user" ? (
				<SideNavbarItems key="/admin-user" style={{ marginLeft: "auto" }}>
					<Link to="/admin-user">Users</Link>
				</SideNavbarItems>
			) : (
				<SideNavbarItems key="/final-user" style={{ marginLeft: "auto" }}>
					<Link to="/final-user">Users</Link>
				</SideNavbarItems>
			)}
			<SideNavbarItems key="/stats">
				<Link to="/stats">Stats</Link>
			</SideNavbarItems>
			<SideNavbarItems
				onClick={handleLogout}
				key="4"
				style={{ marginLeft: "auto" }}
			>
				<Link to="/">Logout</Link>
			</SideNavbarItems>
		</SideNavbar>
	);
};

export default SideMenu;
