import React from "react";
import { Modal, Button } from "antd";

const CustomModal = (props) => {
	const {
		title = "",
		children,
		okText = "Confirm",
		cancelText = "Cancel",
		loading = false,
		isModalVisible,
		handleOk,
		handleCancel,
		footer = [
			<Button key="back" onClick={handleCancel}>
				Cancel
			</Button>,
			<Button key="submit" type="primary" loading={loading} onClick={handleOk}>
				Confirm
			</Button>,
		],
	} = props;

	return (
		<>
			<Modal
				title={title}
				visible={isModalVisible}
				okText={okText}
				cancelText={cancelText}
				onCancel={handleCancel}
				footer={footer}
			>
				{children}
			</Modal>
		</>
	);
};

export default CustomModal;
