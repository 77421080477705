import React from "react";
import Nav from "../../Components/Nav";
import { Row, Col, Form } from "antd";
import { StyledButton, Container } from "../../Components/styled.components";
import { CustomDivider, LoginSection, Heading } from "./styled.components";
import CustomInput from "./../../Components/CustomInput";
import { useNavigate } from "react-router-dom";
import httpClient from "../../axios";
import { openNotification } from "../../helper";
import BackgroundImage from "../../Assets/images/industrial_safety.png";

const LoginContent = () => {
	const navigate = useNavigate();
	const http = httpClient();

	const [loading, setLoading] = React.useState(false);

	const onFinish = (values) => {
		setLoading(true);
		http
			.post("/auth/login", {
				email: values.email,
				password: values.password,
			})
			.then((response) => {
				if (response.status === 200) {
					localStorage.setItem("token", response.data.access_token);
					localStorage.setItem("role", response.data.role);
					if (response.data.data.first_name && response.data.data.last_name) {
						localStorage.setItem(
							"userName",
							`${response.data.data.first_name} ${response.data.data.last_name}`
						);
					} else {
						localStorage.setItem("userName", "Anonymous");
					}
					if (localStorage.getItem('role') === 'super-admin') {
						navigate("/super-admin");
					} else {
						navigate("/admin");
					}
					setLoading(false);
				} else {
					throw response;
				}
			})
			.catch((error) => {
				openNotification({
					type: "error",
					message:
						(error.response && error.response.data.message) || "Error occured",
					errors: (error.response && error.response.data.errors) || [],
				});
				setLoading(false);
			});
	};

	const onFinishFailed = (errorInfo) => {
		console.error("Failed:", errorInfo);
	};

	React.useEffect(() => {
		if (localStorage.getItem("token")) {
			navigate("/super-admin");
		}
	}, []);

	return (
		<>
			<Nav />
			<Container
				style={{
					backgroundImage: `url(${BackgroundImage})`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					height: "calc(100vh - 180px)",
				}}
			>
				<Row>
					<Col xs={24} sm={12} lg={6}>
						<Heading>Solid Waste Operator VR Training</Heading>
					</Col>
				</Row>
				<CustomDivider />
				<LoginSection>
					<Form
						name="login-form"
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
					>
						<Row gutter={16}>
							<Col xs={24}>
								<Heading>LOGIN</Heading>
							</Col>
						</Row>
						<Row>
							<Col xs={24} sm={16} lg={6}>
								<Row gutter={16}>
									<Col xs={24}>
										<CustomInput
											name="email"
											message="Please input your email!"
											placeholder="Email Address"
										/>
									</Col>
									<Col xs={24}>
										<CustomInput
											name="password"
											message="Please input your password!"
											placeholder="Password"
											type="password"
										/>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col xs={12} sm={6} lg={3}>
								<Form.Item>
									<StyledButton loading={loading} htmlType="submit">
										Submit
									</StyledButton>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</LoginSection>
			</Container>
		</>
	);
};

export default LoginContent;
