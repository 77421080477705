import { Table } from 'antd';
import styled from 'styled-components';

export const StyledTable = styled(Table)`
  th, td {
    font-size: 18px;
    text-align: center;
  }
  tr th{
    text-align: center;
  }
  .ant-table-thead tr th{
    background: #51D8D9 0% 0% no-repeat padding-box;
    border: 0px;
    color: #fff;
    height: 60px;
    &::before {
      width: 0px !important;
    }
  }
  td {
    background: #DCDCDC;
  }
`