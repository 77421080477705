import { Link, useLocation, useNavigate } from "react-router-dom";
import { Navbar, NavbarItem, ISCLogo } from "./styled.components";
import { ReactComponent as LogoImage } from "../../Assets/svg/VRTS_Logo.svg";

const Menu = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("userName");
		localStorage.removeItem("role");
		navigate("/");
	};
	const getInitials = () => {
		if (
			localStorage.getItem("userName") &&
			localStorage.getItem("userName") !== null
		) {
			const fullName = localStorage.getItem("userName").split(" ");
			let initials = `${fullName.shift()?.charAt(0) || 'A'}${fullName.pop()?.charAt(0) || ''}`;
			return initials?.toUpperCase();
		} else {
			return "A";
		}
	};
	const getName = () => {
		return localStorage.getItem("userName");
	};
	return (
		<Navbar selectedKeys={location.pathname}>
			<NavbarItem key="1" isdisabled="true">
				<Link to="/">
					<LogoImage style={{ width: "100%", maxWidth: '300px' }} />
				</Link>
			</NavbarItem>
			{location.pathname === "/" ? (
				<></>
			) : (
				<>
					{location.pathname === "/final-user" ? (
						<NavbarItem key="/final-user" style={{ marginLeft: "auto" }}>
							<Link to="/final-user">Users</Link>
						</NavbarItem>
					) : location.pathname === "/admin" ? (
						<NavbarItem key="/admin" style={{ marginLeft: "auto" }}>
							<Link to="/admin">Users</Link>
						</NavbarItem>
					) : (
						<NavbarItem key="/super-admin" style={{ marginLeft: "auto" }}>
							<Link to="/super-admin">Users</Link>
						</NavbarItem>
					)}
					<NavbarItem key="/stats">
						<Link to="/stats">Stats</Link>
					</NavbarItem>
					<NavbarItem
						onClick={handleLogout}
						key="4"
						style={{ marginLeft: "50px" }}
					>
						<div>Logout</div>
					</NavbarItem>
					<NavbarItem key="5" isdisabled="true">
						<ISCLogo>{getInitials()}</ISCLogo>
						<p
							style={{
								display: "inline-flex",
								lineHeight: "25px",
								marginBottom: "0px",
							}}
						>
							{getName()}
						</p>
					</NavbarItem>
				</>
			)}
		</Navbar>
	);
};

export default Menu;
