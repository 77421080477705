import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Col, Row } from "antd";
import Menu from "./Menu";
import SideMenu from "./SideMenu";
import {
	DrawerButton,
	NavContainer,
	StyledDrawer,
	LogoImage,
	BarIcon,
} from "./styled.components";
import logo from "../../Assets/images/logo.png";

const Nav = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const [visible, setVisible] = React.useState(false);
	const showDrawer = () => {
		setVisible(true);
	};
	const onClose = () => {
		setVisible(false);
	};
	return (
		<>
			<Row>
				<NavContainer>
					<Col xs={24} sm={24} md={24} lg={0}>
						<Row gutter={24}>
							{location.pathname !== "/" ? (
								<Col xs={12}>
									<DrawerButton onClick={showDrawer}>
										<BarIcon className="barsBtn">
											<li className="icon-bar"></li>
											<li className="icon-bar"></li>
											<li className="icon-bar"></li>
										</BarIcon>
									</DrawerButton>
								</Col>
							) : (
								<></>
							)}
							<Col xs={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
								<Link to="/">
									<LogoImage
										src={logo}
										style={{
											display: "flex",
											justifyContent: "end",
											alignItems: "end",
										}}
									/>
								</Link>
							</Col>
						</Row>
						<StyledDrawer
							placement="left"
							closable={true}
							onClose={onClose}
							visible={visible}
							maskClosable={true}
						>
							<SideMenu />
						</StyledDrawer>
					</Col>
				</NavContainer>
				<Col span={12}>
					<Menu />
				</Col>
			</Row>
		</>
	);
};

export default Nav;
