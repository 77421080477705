import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CustomSelect from "./CustomSelect";

const NavigationSelect = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const onSelectValue = (value) => {
		navigate(value);
	};

	const [options, setOptions] = React.useState([
		{ label: "Admin User", value: "/admin" },
		{ label: "Trainee", value: "/final-user" },
	]);

	React.useEffect(() => {
		if (localStorage.getItem("role") === "super-admin") {
			setOptions(() => {
				return [
					...options,
					{ label: "Super Admin User", value: "/super-admin" },
				];
			});
		}
	}, []);

	return (
		<CustomSelect
			options={options}
			name="Super"
			defaultValue={
				options.map((option) => option.value === location.pathname)[0]?.label
			}
			value={location.pathname}
			onSelectValue={onSelectValue}
		/>
	);
};

export default NavigationSelect;
