import React from "react";

import { Row, Col, Form, Input } from "antd";
import Nav from "../../Components/Nav";
import {
	Container,
	StyledButton,
	Heading2,
	TableLink,
	Label,
} from "../../Components/styled.components";
import CustomStatTable from "../../Components/CustomStatTable";
import CustomSelect from "../../Components/CustomSelect";
import httpClient from "../../axios";
import { openNotification } from "../../helper";
import moment from "moment";
import CustomModal from "../../Components/CustomModal";
import { StyledSelect } from "../../Components/styled.components";


const StatsOne = () => {
	const http = httpClient();
	const [pagination, setPagination] = React.useState({
		itemsPerPage: 10,
		page: 1,
		totalItems: "",
	});
	const [options] = React.useState([
		{ Label: "Select Module", value: "Module" },
	]);
	const [optionsFilter] = React.useState([
		{ Label: "Filter by", value: "filter" },
	]);
	const { Option } = StyledSelect;

	const [statList, setStatList] = React.useState([]);
	const [details, setDetails] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [isModalVisible, setIsModalVisible] = React.useState(false);
	const [deleteLoading, setDeleteLoading] = React.useState(false);
	const [deleteQuizz, setDeleteQuizz] = React.useState("");
	const [isDeleteModalVisible, setIsDeleteModalVisible] = React.useState(false);
	const [search, setSearch] = React.useState('');
	const [fnGroupLoading, setFnGroupLoading] = React.useState(false);
	const [fnGroup, setFnGroup] = React.useState([]);
	const [groupFilterDefault, setGroupFilterDefault] = React.useState("");
	// const[setResetFilters] =React.useState("");
	const { Search } = Input;

	const fetchFiltered = (data) => {
		fetchStats(null, null, null,data)
	}

	const setResetFilters = () => {
		fetchStats()
	}
	const fetchFnGroupName = () => {
		setFnGroupLoading(true);
		const params = {
			limit: 10000,
			page: 1
		};
		http
			.get("/fn-group", { params })
			.then((response) => {
				if (response.status === 200) {
					if (response.data) {
						const data = response.data.data.map((item) => {
							return {
								key: item.id,
								value: item.name,
							};
						});
						setFnGroup(data);
						setFnGroupLoading(false);
					}
				} else {
					throw response;
				}
			})
			.catch((error) => {
				setFnGroupLoading(false);
				console.error("error", error);
			});
	};

	const fetchStats = (itemsPerPage, page, search, filter) => {
		setLoading(true);
		http
			.get(`quizzes`, {
				params: {
					limit: itemsPerPage || 10,
					page: page || 1,
					search: search,
					group_id: filter
				},
			})
			.then((response) => {
				if (response.status === 200) {
					if (response.data) {
						const data = response.data.data.map((item) => {
							return {
								key: item.id,
								name: "Jhon doe",
								quizes: item.quizzes,
							};
						});
						setPagination({
							itemsPerPage: response.data.meta.per_page,
							totalItems: response.data.meta.total,
							page: response.data.meta.current_page,
						});
						setStatList({ data, meta: response.data.meta });
						setLoading(false);
					}
				} else {
					throw response;
				}
			})
			.catch((error) => {
				setLoading(false);
				openNotification({
					type: "error",
					message:
						(error.response && error.response.data.message) || "Error occured",
					errors: (error.response && error.response.data.errors) || [],
					status: (error.response && error.response.status) || "error",
				});
				console.error("error", error);
			});
	};


	const onSearch = (data) => {
		setSearch(data);
		fetchStats(pagination.itemsPerPage, 1, data);
	};

	const onChangePagination = (page, pageSize) => {
		setPagination({
			itemsPerPage: pageSize,
			page: page,
		});
		fetchStats(pageSize, page);
	};

	const onSeeDetails = (data) => {
		if (data && data.sections) {
			setDetails(data.sections);
			setIsModalVisible(true);
		}
	};
	const handleCancelModal = () => {
		setIsModalVisible(false);
	};
	// Handle delete
	const handleOpenDeleteModal = (data) => {
		setDeleteQuizz(data.key);
		setIsDeleteModalVisible(true);
	};
	const handleDelete = (quizz) => {
		setDeleteLoading(true);
		http
			.delete(`/quizzes/${quizz}`)
			.then((response) => {
				if (response.status === 204) {
					handleCancelDelete();
					setDeleteLoading(false);
					fetchStats();
				} else {
					throw response;
				}
			})
			.catch((error) => {
				console.error("error", error);
				setDeleteLoading(false);
			});
	};
	const handleCancelDelete = () => {
		setIsDeleteModalVisible(false);
	};
	const handleDownloadStats = () => {
		console.log('/download stats here')
	}

	React.useEffect(() => {
		fetchStats();
		fetchFnGroupName();
	}, []);

	const columns = [
		{
			title: "Waste Management Training",
			children: [
				{
					title: "User Name",
					render: (text) => {
						if (text.quizes[0] && text.quizes[0].user) {
							return text.quizes[0].user.first_name + ' ' + text.quizes[0].user.last_name;
						} else {
							return "";
						}
					},
					// dataIndex: "user",
					// render: (text) => {
					// 	console.log('TBH', text);
					// 	// if (text.quizes[0] && text.quizes[0].user) {
					// 	// 	return text.quizes[0].user ? text.quizes[0].user.first_name + " " + text.quizes[0].user.last_name : '';
					// 	// } else {
					// 	// 	return "";
					// 	// }
					// 	return "";
					// },
					key: "user",
				},
			],
		},
		{
			title: "Landfill",
			children: [
				{
					title: "Start Date",
					render: (text) => {
						if (text.quizes[0] && text.quizes[0].start_date) {
							return moment(new Date(text.quizes[0].start_date)).format(
								"MM/DD/YYYY"
							);
						} else {
							return "";
						}
					},
					key: "landfill_start_date",
				},
				{
					title: "Completion Date",
					render: (text) => {
						if (text.quizes[0] && text.quizes[0].completion_date) {
							return moment(new Date(text.quizes[0].completion_date)).format(
								"MM/DD/YYYY"
							);
						} else {
							return "0%";
						}
					},
					key: "landfill_completion_date",
				},
				{
					title: "Completion %",
					render: (text) => {
						if (text.quizes[0] && text.quizes[0].completion_percentage) {
							return `${Number.isSafeInteger(text.quizes[0].completion_percentage)
								? text.quizes[0].completion_percentage
								: text.quizes[0].completion_percentage.toFixed(2)
								}%`;
						} else {
							return "0%";
						}
					},
					key: "landfill_completion_percentage",
				},
				{
					title: "Score %",
					render: (text) => {
						if (text.quizes[0] && text.quizes[0].score_percentage) {
							return `${Number.isSafeInteger(text.quizes[0].score_percentage)
								? text.quizes[0].score_percentage
								: text.quizes[0].score_percentage.toFixed(2)
								}%`;
						} else {
							return "";
						}
					},
					key: "landfill_score_percentage",
				},
				{
					title: "Result",
					render: (text) => {
						if (text.quizes[0] && text.quizes[0].score_percentage) {
							return text.quizes[0].score_percentage >= 60 ? (
								<span style={{ color: "#00A706" }}>PASS</span>
							) : (
								<span style={{ color: "#BB0000" }}>FAIL</span>
							);
						} else if (
							text.quizes[1] &&
							text.quizes[1].score_percentage === 0
						) {
							return <span style={{ color: "#BB0000" }}>FAIL</span>;
						} else {
							return "N/A";
						}
					},
					key: "landfill_result",
				},
				{
					title: "Details",
					render: (text) => (
						<TableLink onClick={() => onSeeDetails(text.quizes[0])}>
							See
						</TableLink>
					),
				},
			],
		},
		{
			title: "Transfer Station",
			children: [
				{
					title: "Start Date",
					render: (text) => {
						if (text.quizes[1] && text.quizes[1].start_date) {
							return moment(new Date(text.quizes[1].start_date)).format(
								"MM/DD/YYYY"
							);
						} else {
							return "";
						}
					},
					key: "transfer_start_date",
				},
				{
					title: "Completion Date",
					render: (text) => {
						if (text.quizes[1] && text.quizes[1].completion_date) {
							return moment(new Date(text.quizes[1].completion_date)).format(
								"MM/DD/YYYY"
							);
						} else {
							return "";
						}
					},
					key: "transfer_completion_date",
				},
				{
					title: "Completion %",
					render: (text) => {
						if (text.quizes[1] && text.quizes[1].completion_percentage) {
							return `${Number.isSafeInteger(text.quizes[1].completion_percentage)
								? text.quizes[1].completion_percentage
								: text.quizes[1].completion_percentage.toFixed(2)
								}%`;
						} else {
							return "0%";
						}
					},
					key: "transfer_completion_percentage",
				},
				{
					title: "Score %",
					render: (text) => {
						if (text.quizes[1] && text.quizes[1].score_percentage) {
							return `${Number.isSafeInteger(text.quizes[1].score_percentage)
								? text.quizes[1].score_percentage
								: text.quizes[1].score_percentage.toFixed(2)
								}%`;
						} else {
							return "0%";
						}
					},
					key: "transfer_score_percentage",
				},
				{
					title: "Result",
					render: (text) => {
						if (text.quizes[1] && text.quizes[1].score_percentage) {
							return text.quizes[1].score_percentage >= 60 ? (
								<span style={{ color: "#00A706" }}>PASS</span>
							) : (
								<span style={{ color: "#BB0000" }}>FAIL</span>
							);
						} else if (
							text.quizes[1] &&
							text.quizes[1].score_percentage === 0
						) {
							return <span style={{ color: "#BB0000" }}>FAIL</span>;
						} else {
							return "N/A";
						}
					},
					key: "transfer_result",
				},
				{
					title: "Details",
					render: (text) => (
						<TableLink onClick={() => onSeeDetails(text.quizes[1])}>
							See
						</TableLink>
					),
				},
			],
		}
	];
	return (
		<>
			<Nav />
			<Container>
				<Row
					gutter={[16, 16]}
					style={{ justifyContent: "space-between", alignItems: "flex-end" }}
				>
					<Col xs={24} md={12} lg={6}>
						<Heading2>Stats</Heading2>
					</Col>
					<Col
						xs={24}
						style={{ display: "flex", justifyContent: "flex-end" }}
					>
						<Search
							style={{ maxWidth: "350px" }}
							placeholder="Search"
							allowClear
							enterButton="Search"
							size="large"
							onSearch={onSearch}
						/>
					</Col>
					<Col xs={24} md={6} lg={3}>
						{/* <StyledButton onClick={handleDownloadStats} style={{marginBottom: '10px'}}>Download</StyledButton> */}
					</Col>
				</Row>
				{<Row gutter={[16, 8]}>
					<Col
						xs={24}
						sm={12}
						md={8}
						lg={6}
						xl={4}
						style={{ display: "flex", alignItems: "flex-start" }}
					>
						<StyledSelect defaultValue={groupFilterDefault} onSelect={fetchFiltered}>
							<Option key='option-a'  value=''>
								Select group
							</Option>
							{fnGroup.map((option, index) => {
								return (
									<Option key={option.key || `option-${index}`} value={option.key}>
										{option.value}
									</Option>
								);
							})}
						</StyledSelect>
					</Col>
					{/* <Col
						xs={12}
						sm={12}
						md={4}
						lg={3}
						xl={2}
						style={{ display: "flex", justifyContent: "flex-start", width: "" }}
					>
						<StyledButton>Apply</StyledButton>
					</Col> */}
					<Col
						xs={12}
						sm={12}
						md={4}
						lg={3}
						xl={2}
						style={{ display: "flex", justifyContent: "flex-start" }}
					>
						<StyledButton onClick={setResetFilters}>Reset</StyledButton>
					</Col>
				</Row>}
				<Row style={{ marginTop: "50px" }}>
					<Col span={24}>
						<CustomStatTable
							loading={loading}
							dataSource={statList}
							columns={columns}
							pagination={{
								pageSize: pagination.itemsPerPage,
								current_page: pagination.page,
								total: pagination.totalItems,
								showSizeChanger: true,
								onChange: onChangePagination,
							}}
						/>
					</Col>
				</Row>

				<CustomModal
					isModalVisible={isModalVisible}
					title="Quiz Details"
					handleCancel={handleCancelModal}
					destroyOnClose
					footer={null}
				>
					{details &&
						details.map((section, index) => (
							<Row gutter={[16, 16]} style={{ marginBottom: "15px" }}>
								<Col xs={24}>
									<h2>{section.name}</h2>
								</Col>
								{section.questions &&
									section.questions.map((question, questionIndex) => (
										<>
											<Col xs={24}>
												<Label>{questionIndex + 1}. Question</Label>
												<div>{question.title}</div>
											</Col>
											<Col xs={24}>
												<Label>Answer</Label>
												<div>{question.answer}</div>
											</Col>
											<Col xs={24}>
												<Label>Corrent Answer</Label>
												<div>{question.correct_answer}</div>
											</Col>
										</>
									))}
							</Row>
						))}
				</CustomModal>
				<CustomModal
					isModalVisible={isDeleteModalVisible}
					title="Confirm Delete Quizz"
					loading={deleteLoading}
					handleOk={() => handleDelete(deleteQuizz)}
					handleCancel={handleCancelDelete}
				>
					<div>
						Are you sure you want to delete this quizz. This action is
						irreversible!
					</div>
				</CustomModal>
			</Container>
		</>
	);
};

export default StatsOne;
