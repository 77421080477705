import styled from "styled-components";
import { Menu, Drawer, Button } from "antd";

export const Navbar = styled(Menu)`
	background-color: #2a3840;
	height: 130px;
	width: 100%;
	display: flex;
	position: fixed;
	z-index: 1000;
	padding: 0px 120px;
	border: 0px;
	@media (max-width: 576px) {
		//xs
		display: none;
	}
	@media (min-width: 577px) and (max-width: 767px) {
		//sm
		display: none;
	}
	@media (min-width: 769px) and (max-width: 992px) {
		//md
		display: none;
	}
	@media (min-width: 993px) and (max-width: 1200px) {
		//lg
	}
`;

export const NavContainer = styled.div`
	display: flex;
	height: 130px;
	width: 100%;
	justify-content: start;
	align-items: center;
	flex-direction: row;
	background-color: #2a3840;
	margin-top: 0px;

	border: 0px;
	z-index: 10000;
	@media (max-width: 576px) {
		//xs
		padding: 0px 20px;
	}
	@media (min-width: 577px) and (max-width: 768px) {
		//sm
		padding: 0px 30px;
	}
	@media (min-width: 769px) and (max-width: 992px) {
		//md
		padding: 0px 40px;
	}
	@media (min-width: 993px) and (max-width: 1200px) {
		//lg
		display: none;
	}
	@media (min-width: 1201px) {
		display: none;
	}
`;

export const NavbarItem = styled(Menu.Item)`
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	height: 130px !important;
	text-transform: uppercase;
	min-width: 120px;
	margin-bottom: 0px !important;
	margin-top: 0px !important;
	color: #fff;

	&.ant-menu-item-selected {
		background: ${(props) =>
			props.isdisabled
				? "#2a3840 !important"
				: `transparent linear-gradient(0deg, #51d8d9 0%, #2a3840 100%) 0%
			0% no-repeat padding-box`};
		color: ${(props) =>
			props.isdisabled
				? "#fff !important" : '#51d8d9 !important'};
		cursor: ${(props) =>
			props.isdisabled
				? "unset !important" : 'pointer !important'};
		.ant-menu-title-content a {
			color: #51d8d9 !important;
		}
	}
	.ant-menu-title-content a {
		color: #ffffff;
	}
	.ant-menu-title-content {
		display: flex;
		align-items: center;
	}
	&:hover {
		background: ${(props) =>
			props.isdisabled
				? "#2a3840 !important"
				: `transparent linear-gradient(0deg, #51d8d9 0%, #2a3840 100%) 0%
			0% no-repeat padding-box`};
		color: ${(props) =>
			props.isdisabled
				? "#fff !important" : '#51d8d9 !important'};
		cursor: ${(props) =>
			props.isdisabled
				? "unset !important" : 'pointer !important'};
		.ant-menu-title-content a {
			color: #51d8d9 !important;
		}
	}
`;

export const ISCLogo = styled.span`
	width: 50px;
	height: 50px;
	background: #51d8d9;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	letter-spacing: 0px;
	border-radius: 50%;
	margin-right: 5px;
	color: #fff !important;
	margin-right: 10px;
`;

export const LogoImage = styled.img`
	width: 210px;
	@media (max-width: 576px) {
		//xs
		width: 150px;
	}
	@media (min-width: 577px) and (max-width: 768px) {
		//sm
		width: 150px;
	}
`;

// ********Side menu bar*********

export const DrawerButton = styled(Button)`
	background-color: #51d8d9;
	color: white;
	border: 1px solid white;
	z-index: 1000;
	margin-top: 0px;
	&:hover,
	&:focus {
		background-color: #8aaeae;
		color: white;
		border: 0px;
	}
	justify-content: center;
	align-items: center;
	@media (max-width: 576px) {
		//xs
		width: 50px;
		height: 50px;
		margin-top: 0px;
	}
	@media (min-width: 577px) and (max-width: 768px) {
		//sm
		width: 50px;
		height: 50px;
		margin-top: 0px;
	}
	@media (min-width: 769px) and (max-width: 992px) {
		//md
		width: 50px;
		height: 50px;
		margin-top: 0px;
	}
	@media (min-width: 993px) and (max-width: 1200px) {
		//lg
		display: none;
	}
	@media (min-width: 1201px) {
		display: none;
	}
`;

export const StyledDrawer = styled(Drawer)`
	z-index: 100000;
	.ant-drawer-content {
		width: 100%;
	}
	.ant-drawer-header,
	.ant-drawer-close {
		padding-left: 70px;
		background-color: #2a3840;
		color: white;
		padding-bottom: 0px !important;
	}
	.ant-drawer-content-wrapper,
	.ant-drawer-left.ant-drawer-open {
		box-shadow: none !important;
		width: 300px !important;
	}
	.ant-drawer-body {
		width: 100%;
		padding: 0;
	}
	@media (min-width: 769px) and (max-width: 992px) {
		//md
	}
	@media (min-width: 993px) and (max-width: 1200px) {
		//lg
		display: none;
	}
	@media (min-width: 1201px) {
		display: none;
	}
`;

export const SideNavbar = styled(Menu)`
	background-color: #2a3840;
	height: 100%;
	width: 300px;
	/* display: flex; */
	flex-direction: column;
	position: fixed;
	z-index: 100000;
	padding-top: 50px;
`;

export const SideNavbarItems = styled(Menu.Item)`
	display: flex;
	justify-content: center;
	align-items: center;
	/* flex-direction: column; */
	font-size: 20px;
	height: 100px !important;
	text-transform: uppercase;
	min-width: 90px;
	margin-bottom: 0px !important;
	margin-top: 0px !important;
	color: #fff;
	&.ant-menu-item-selected {
		background: ${(props) =>
			props.isdisabled
				? ""
				: `transparent linear-gradient(0deg, #51d8d9 0%, #2a3840 100%) 0%
			0% no-repeat padding-box`};
		color: #51d8d9 !important;
		.ant-menu-title-content a {
			color: #51d8d9 !important;
		}
	}
	.ant-menu-title-content a {
		color: #ffffff;
	}
	.ant-menu-title-content {
		display: flex;
		align-items: center;
	}
	&:hover {
		background: ${(props) =>
			props.isdisabled
				? ""
				: `transparent linear-gradient(0deg, #51d8d9 0%, #2a3840 100%) 0%
			0% no-repeat padding-box`};
		color: #51d8d9 !important;
		.ant-menu-title-content a {
			color: #51d8d9 !important;
		}
	}
`;

export const BarIcon = styled.ul`
	display: flex;
	flex-direction: column;
	margin: 0px;
	padding: 0px;
	li {
		text-decoration: none;
		list-style: none;
		width: 100%;
		height: 2px;
		margin-bottom: 5px;
    background: white;
	}
`;
