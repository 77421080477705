import { Table } from 'antd';
import styled from 'styled-components';

export const StyledTable = styled(Table)`
  td {
    font-size: 12px;
    text-align: center;
  }
  tr:first-child th {
    font-size: 16px;
  }
  tr:first-child th:first-child, tr:first-child th:last-child {
    background-color: #2A3840;
    color: #FFFFFF;
  }
  tr:first-child th:nth-child(2), tr:nth-child(2) th:first-child {
    background-color: #B7B7B7;
    color: #000000;
  }
  tr:first-child th:nth-child(3) {
    background-color: #DEDEDE;
    color: #000000;
  }
  tr:nth-child(2) th:nth-child(n+2):nth-child(-n+7) {
    background-color: #D9D9D9;
  }
  tr:nth-child(2) th:nth-child(n+8):nth-child(-n+14) {
    background-color: #F5F5F5;
  }
  tr:nth-child(odd) td:nth-child(n){
    background-color: #FFFFFF;
  }
  th {
    font-size: 12px;
  }
  .ant-table-thead tr th{
    border: 0px;
    height: 60px;
    text-align: center;
    &::before {
      width: 0px !important;
    }
  }
  td {
    background: #DCDCDC;
  }
`